

































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import globalMixins from '@/mixins/global.ts'

@Component({
  mixins: [globalMixins]
})
export default class Tree extends Vue<globalMixins> {
  @Prop()
  treeList: any[]
  @Prop()
  tempName: string

  maxexpandId: number = 1
  openParentId: number = null
  parentKey: number = null
  childKey: number = null
  non_maxexpandId: number = this.maxexpandId
  isLoadingTree: Boolean = true
  tempNameEditable: Boolean = false
  setTree: any = []
  iconSize: String = 'mini'
  tempNameComputed: String = ''
  openKeys: any[] = JSON.parse(sessionStorage.getItem('openKeys')) || []
  defaultProps: Object = {
    children: 'children',
    label: 'name'
  }
  topicForm: Object = {
    title: ''
  }
  childObj: Object = {}
  copyFormVisible: boolean = false
  // copy参数
  groupId: Number = null
  formId: Number = null
  // 判断是否copy是group: true 或者 form: false
  copyGroupFlag: boolean = true

  handleAddTop () {
    //添加顶级节点
    let obj = {
      name: '新增一级标题',
      isTop: true,
      children: [],
      allow_override: true,
      require_approve: true
    }
    this.$emit('addGroup', obj)
  }
  // 模板名称修改
  nameEdit () {
    this.tempNameEditable = true
    this.$nextTick(() => {
       let inputElem = this.$refs.nameEditInput as any
       inputElem.focus()
    })
  }
  // 失去焦点
  nameLoseblur () {
    this.tempNameEditable = false
    // 发送修改请求
    this.$emit('tempNameChange', this.tempNameComputed)
  }
  // 记录展开的节点
  handleNodeExpand (data, node, nodeArr) {
    // 避免重复push
    if (this.openKeys.indexOf(data.key) === -1) {
      this.openKeys.push(data.key)
    }
    this.setSession('openKeys', this.openKeys)
    this.openKeys = JSON.parse(sessionStorage.getItem('openKeys'))
  }
  handleNodeCollapse (data, node, nodeArr) {
    let i = this.openKeys.indexOf(data.id)
    this.openKeys.splice(i, 1)
    this.setSession('openKeys', this.openKeys)
    this.openKeys = JSON.parse(sessionStorage.getItem('openKeys'))
  }
  setSession(key, value) {
    let tempValue = JSON.stringify(value)
    window.sessionStorage[key] = tempValue
  }
  // node
  handleNodeClick (data, node, nodeArr) {
    if (data.children) {
      this.$emit('clickParentBtn')
    } else {
      // 点击子菜单
      // 取到点击的子元素的父id
      let parentKey = node.parent.key
      this.parentKey = parentKey
      let childKey = data.id
      this.childKey = childKey
      // 先找到点击的子元素对应的父元素
      this.getChildObj(Number(parentKey), Number(childKey))
    }
  }
  // 找到父节点 子节点数据
  getChildObj (parentKey, childKey) {
    let parentObj = this.treeList.find(elem => {
      return elem.id === Number(parentKey)
    })
    // 再找到点击的子元素对应的具体数据
    this.childObj = parentObj.forms.length > 0 && parentObj.forms.find(item => {
      return item.id === Number(childKey)
    })
    this.$emit('currentList', this.childObj)
  }
  clearKeys () {
    this.parentKey = null
    this.childKey = null
  }
  dropEnd () {
    this.$emit('treeSort', this.setTree)
  }
  // 只能放在主节点上,不允许放置在节点内部
  allowDrop(draggingNode, dropNode, type) {
    // return type !== 'inner' || type !== 'next'
    let isDragParent = draggingNode.data.children
    let isDropParent = dropNode.data.children
    // 1) 点击的是父节点, 放置在子节点里面 draggingNode.data.children && !dropNode.data.children
    if (isDragParent && !isDropParent) {
      return false
    }
    // 2) 点击的是父节点, 放置在父节点平级 draggingNode.data.children && dropNode.data.children
    else if (isDragParent && isDropParent) {
      return type !== 'inner'
    }
    // 3) 点击的是子节点, 放置在跟父节点平级的地方 !draggingNode.data.children && dropNode.data.children
    else if (!isDragParent && isDropParent) {
      return false
    }
    // 4) 点击的是子节点, 放置在跟子节点平级的地方 !draggingNode.data.children && !dropNode.data.children
    else if (!isDragParent && !isDropParent) {
      return type !== 'inner'
    }
  }
  // 只有主节点可以拖拽
  allowDrag(draggingNode) {
    return true
  }
  NodeBlur(n, d) {
    if(n.isEdit){
      this.$set(n, 'isEdit', false)
    }
    // this.$nextTick(() => {
    //   (this.$refs['slotTreeInput'+d.id] as any) && (this.$refs['slotTreeInput'+d.id] as any).$refs.input.focus()
    // })
    if (d.children) {
      // 父节点
      let params = {
        formGroupId: d.id,
        name: d.name,
        allow_override: true,
        require_approve: true
      }
      this.$emit('groupNameEdit', params, {isParent: true})
    } else {
      // 子节点
      let params = {
        formId: d.id,
        name: d.name,
        allow_override: true,
        require_approve: true
      }
      this.$emit('groupFormNameEdit', params, {isParent: false})
    }
    this.clearKeys()
  }
  NodeEdit(n, d) {
    if(!n.isEdit){
      this.$set(n, 'isEdit', true)
    }
  }
  // 复制节点功能
  NodeCopy (n, d) {
    this.copyFormVisible = true
     this.$nextTick(() => {
      let PassForm = this.$refs.ruleForm as HTMLFormElement
      PassForm.resetFields()
    })
    // 如果是父节点了, n.parent.key === undefined
    if (!n.parent.key) {
      // 复制父节点
      this.copyGroupFlag = true
      this.groupId = d.id
    } else {
      this.copyGroupFlag = false
      // 复制子节点
      this.groupId = n.parent.key
      this.formId = d.id
    }
  }
  // 复制题目
  copySubmit () {
    let PassForm = this.$refs.ruleForm as HTMLFormElement
    PassForm.validate((valid) => {
      if (valid) {
        let query = {
          name: (this.topicForm as any).title,
          groupId: this.groupId
        }
        if (this.copyGroupFlag) {
          // 父节点的复制
          this.$emit('copyGroupNode', query)
        } else {
          // 子节点
          query = Object.assign({}, query, {formId: this.formId})
          this.$emit('copyGroupFormNode', query)
        }
        this.copyFormVisible = false
      }
    })
    
  }
  NodeDel(n, d) {
    let that = this
    if (d.children && d.children.length !== 0){
      this.warnMsg('此节点有子级，不可删除！')
      return false
    } else {
      //新增节点可直接删除，已存在的节点要二次确认
      //删除操作
      let DelFun = () => {
        //节点同级数据
        let _list = n.parent.data.children || n.parent.data
        let _index = _list.map((c) => c.id).indexOf(d.id)
        _list.splice(_index, 1)
        // 是 父节点
        if (n.data.children) {
          this.$emit('deleteForm', n.key, {flag: 'group'})
        } else {
          this.$emit('deleteForm', n.data.id, {flag: 'form'})
        }
      }
      this.$confirm('是否删除此节点？', '提示',{
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DelFun()
      }).catch((err) => {
      })
    }
  }
  NodeAdd(n, d) {
    //判断层级
    if(n.level >= 2){
      this.warnMsg("最多只支持2级！")
      return false
    }
    // debugger
    
    //新增数据
    let obj = {
      formGroupId: d.id,
      name: '新增节点',
      allow_override: true,
      require_approve: true,
      isTop: false
    }
    // d.children.push(obj)
    //同时展开节点
    // if(!n.expanded){
    //   n.expanded = true
    // }
    if (this.openKeys.indexOf(n.key) === -1) {
      this.openKeys.push(n.key)
    }
    this.setSession('openKeys', this.openKeys)
    this.openKeys = JSON.parse(sessionStorage.getItem('openKeys'))
    this.$emit('groupFormAdd', obj, n)
  }
  secondTree (arr) {
    let newNode = []
    if (arr.length > 0) {
      arr.map((v, i) => {
        newNode.push({
          id: v.id,
          name: v.name
        })
      })
    }
    return newNode
  }
  @Watch('treeList')
  ontreeListChange(val: any, old: any) {
    this.setTree = []
    val.map((item, index) => {
      this.setTree.push({
        id: item.id,
        name: item.name,
        children: this.secondTree(item.forms),
        key: item.key
      })
    })
    if (this.parentKey !== null && this.childKey !== null) {
       this.getChildObj(this.parentKey, this.childKey)
    }
  }
  @Watch('tempName')
  onTempNameChange (val: string, old: string) {
    if (val) {
      this.tempNameComputed = this.tempName
    }
  }
}
