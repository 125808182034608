



































import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Action } from 'vuex-class'
import tempTree from '@/components/tempConfig/Tree.vue'
import topicConfig from '@/components/tempConfig/topicConfig.vue'
import tempConfig from '@/api/tempConfig'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    tempTree,
    topicConfig
  },
  mixins: [globalMixins]
})
export default class TempIndex extends Vue<globalMixins> {
  project: object = {}
  loading: boolean = false
  // 题目是否可点击
  configFlag: boolean = false

  @Getter('tempInfo') tempInfo: Object
  @Action('tempList') tempList: Function
  @Action('copyGroup') copyGroup: Function
  @Action('copyGroupForm') copyGroupForm: Function
  @Action('addFormGroup') addFormGroup: Function
  @Action('updateFormGroup') updateFormGroup: Function
  @Action('addFormGroupForm') addFormGroupForm: Function
  @Action('updateFormGroupForm') updateFormGroupForm: Function
  @Action('updateFormGroupFormField') updateFormGroupFormField: Function
  @Action('editTempName') editTempName: Function
  @Action('delFormGroup') delFormGroup: Function
  @Action('sortFormGroupForm') sortFormGroupForm: Function
  currentListData: object = {}
  created () {
    this.project = JSON.parse(localStorage.getItem("projects"))
    this.doTempList()
  }
  get tempName () {
    return (this.tempInfo as any).name
  }
  get treeList () {
    return (this.tempInfo as any).groups
  }
  clickParentBtn () {
    this.configFlag = false
  }
  currentList (data) {
    this.configFlag = true
    this.currentListData = Object.assign({}, data)
  }
  // 复制父节点
  copyGroupNode (query) {
    this.loading = true
    let params = {
      projectId: (this.project as any).id,
      ...query
    }
    this.copyGroup({ params }).then(() => {
      this.loading = false
      this.doTempList()
    }).catch(err => {
      this.loading = false
      this.errorMsg('节点复制失败, 请刷新后重试!')
    })
  }
   // 复制子节点
  copyGroupFormNode (query) {
    this.loading = true
    let params = {
      projectId: (this.project as any).id,
      ...query
    }
    this.copyGroupForm({ params }).then(() => {
      this.loading = false
      this.doTempList()
    }).catch(err => {
      this.loading = false
      this.errorMsg('节点复制失败, 请刷新后重试!')
    })
  }
  // tree排序
  treeSort (arr) {
    let params = {
      projectId: (this.project as any).id,
      groups: [],
      forms: []
    }
    arr.map((item, index) => {
      params.groups.push(item.id)
      let formArr = []
      item.children.map((v, i) => {
        formArr.push(v.id)
      })
      params.forms.push(formArr)
    })
    this.sortFormGroupForm({ params }).then(() => {
      this.successMsg('排序更新成功!')
    }).catch(err => {
      this.errorMsg('排序更新失败，请稍后重试！')
      this.doTempList()
    })
  }
  // 提交修改 / 新增
  fieldSubmit (obj, isNewCreate) {
    if (isNewCreate) {
      (this.currentListData as any).fields.push(obj)
    } else {
      (this.currentListData as any).fields.find((item, index) => {
        if (item.id === obj.id) {
          (this.currentListData as any).fields[index] = obj
        }
      })
    }
    let params = {
      projectId: (this.project as any).id,
      formId: (this.currentListData as any).id,
      data: [...(this.currentListData as any).fields]
    }
    this.updateFormGroupFormField({ params }).then(() => {
      this.doTempList()
    }).catch(() => {
      this.errorMsg('操作失败,请稍后重试!')
      this.doTempList()
    })
  }
  // 删除form
  deleteForm (id, type) {
    let params = {
      id: id,
      type: type.flag
    }
    this.delFormGroup({ params }).then(() => {
      this.successMsg('删除成功!')
      this.doTempList()
      this.$nextTick(() => {
        (this.$refs.treeConfig as any).clearData()
      })
    }).catch((err) => {
      this.errorMsg(err)
    })
  }
  // 模板名称修改
  tempNameChange (value) {
    let params = {
      name: value,
      projectId: (this.project as any).id
    }
    this.editTempName({ params }).then(() => {
      this.successMsg('病历模板名称修改成功!')
    }).catch(() => {
      this.errorMsg('病历模板名称修改失败,请稍后重试!')
    })
  }
  // 新增group
  addGroup (obj) {
    this.loading = true
    let arr =  (this.tempInfo as any).groups
    let index = (this.tempInfo as any).groups.length
    let params = {
      projectId: (this.project as any).id,
      ...obj,
      sequence: index > 0 ? arr[index - 1].sequence + 1 : 1
    }
    this.addFormGroup({ params }).then(() => {
      this.doTempList()
      this.loading = false
    }).catch(() => {
      this.errorMsg('修改失败,请稍后重试!')
      this.doTempList()
      this.loading = false
    })
  }
  // 新增 group - form
  groupFormAdd (obj, node) {
    this.loading = true
    let newObj = (this.tempInfo as any).groups.find(item => {
      return item.id ===obj.formGroupId
    })
    let index = newObj.forms.length
    let arr = newObj.forms
    // 取form的最後一個sequence
    let params = {
      projectId: (this.project as any).id,
      ...obj,
      sequence: index > 0 ? arr[index - 1].sequence + 1 : 1
    }
    this.addFormGroupForm({ params }).then(() => {
      this.doTempList()
      this.loading = false
    }).catch(() => {
      this.errorMsg('修改失败,请稍后重试!')
      this.doTempList()
      this.loading = false
    })
  }
  // group 名称修改
  groupNameEdit (node) {
    let params = {
      projectId: (this.project as any).id,
      ...node
    }
    if (this.isRegExp(node.name) || node.name === '') {
      this.errorMsg('名称不能为空!')
      this.doTempList()
    } else {
      this.updateFormGroup({ params }).then(() => {
        this.doTempList()
        this.$nextTick(() => {
          (this.$refs.treeConfig as any).clearData()
        })
      }).catch(() => {
        this.errorMsg('修改失败,请稍后重试!')
        this.doTempList()
      })
    }
  }
  // group-form  名称修改
  groupFormNameEdit (node) {
    let params = {
        projectId: (this.project as any).id,
        ...node
      }
      if (this.isRegExp(node.name) || node.name === '') {
        this.errorMsg('名称不能为空!')
        this.doTempList()
      } else {
        this.updateFormGroupForm({ params }).then(() => { 
          this.doTempList()
          this.$nextTick(() => {
            (this.$refs.treeConfig as any).clearData()
          })
        }).catch(() => {
          this.errorMsg('修改失败,请稍后重试!')
          this.doTempList()
        })
      }
  }
  // 判断修改时用户输入或者 空格true || false(有内容或者'')
  isRegExp(val) {
    let regu = '^[ ]+$'
    let re = new RegExp(regu)
    return re.test(val)
  }
  // 配置标题列表
  doTempList () {
    this.loading = true
    let params = {
      projectId: (this.project as any).id
    }
    this.tempList({ params }).then(() => {
      this.loading = false
    }).catch((err) => {
      this.loading = false
    })
  }
  // 更新filed
  groupFormFieldUpdate (data) {
    let newData = []
    data.map((item, index) => {
      newData.push({
        ...item,
        sequence: Number(index) + 1
      })
    })
    let params = {
      projectId: (this.project as any).id,
      formId: (this.currentListData as any).id,
      data: newData
    }
     this.updateFormGroupFormField({ params }).then(() => {
       this.successMsg('字段排序更新成功!')
      this.doTempList()
    }).catch(() => {
      this.errorMsg('操作失败,请稍后重试!')
      this.doTempList()
    })
  }
}
