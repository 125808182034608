















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import draggable from 'vuedraggable'
@Component({
    components: {
      draggable
    }
})
export default class Table extends Vue {
  @Prop()
  data: object

  topicData: object[] = []
  handleEdit ($index, $row) {
    this.$emit('dialogShow', $index, $row, {newAdd: false})
  }
  handleDelete ($index, $row) {
    this.$emit('deleteField', $index, $row)
  }
  clearTableData () {
    this.topicData = []
  }
  typeChange (type) {
    switch (type) {
      case 'image':
        return '图片'
      case 'number':
        return '数值'
      case 'select':
        return '单选'
      case 'multi-select':
        return '多选'
      case 'text':
        return '文本'
      case 'date':
        return '日期'
      case 'time':
        return '时刻'
      case 'dicom':
        return 'DICOM'
    }
  }
  onEnd () {
    this.$emit('fieldUpdate', this.topicData)
  }
  @Watch('data')
  onDataChanged(val: any, oldVal: any) {
    if (val.fields && val.fields.length !== 0) {
      this.topicData = []
      if (val.fields.length > 0) {
        val.fields.map((item, index) => {
          this.topicData.push({
            is_enabled: item.is_enabled ? '启用' : '不启用',
            is_required: item.is_required ? '必填' : '非必填',
            ...item
          })
        })
      }
    } else {
      this.topicData = []
    }
  }
}
