
























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import topicTable from '@/components/tempConfig/Table.vue'
import globalMixins from '@/mixins/global.ts'

@Component({
  components: {
    topicTable
  },
  mixins: [globalMixins]
})
export default class Config extends Vue<globalMixins> {
  @Prop()
  currentListData: object
  @Prop()
  configFlag: boolean

  dialogFormVisible: boolean = false
  isNewCreate: boolean = false
  dialogTitle: string = '设置题目 (单选类型)'
  iconSize: String = 'mini'
  selectIndex: number = 1
  numberIndex: number = 1
  currentObj: object = {}
  form: object = {
    name: '',
    is_required: true,
    is_enabled: true,
    options: []
  }
  selectOptions: object[] = [{
    value: ''
  }]
  numberOptions: object[] = [{
    value: '',
    condition: ''
  }]
  formLabelWidth: string = '120px'
  currentType: string = ''
  topics: object[] = [{
    name: '单选',
    value: "select",
    icon: 'icon-checkbox'
  }, {
    name: '多选',
    value: 'multi-select',
    icon: 'icon-duoxuankuang'
  }, {
    name: '数值',
    value: 'number',
    icon: 'icon-shuzi'
  }, {
    name: '文本',
    value: 'text',
    icon: 'icon-wenben'
  }, {
    name: '图片',
    value: 'image',
    icon: 'icon-tupianimgyulan'
  }, {
    name: '日期',
    value: 'date',
    icon: 'icon-ic_calendar'
  }, {
    name: '时刻',
    value: 'time',
    icon: 'icon-icon-time'
  }, {
    name: 'DICOM',
    value: 'dicom',
    icon: 'icon-yiliaoweisheng'
  }]
  
  get isSelectOrMulti () {
    return ['multi-select', 'select'].includes(this.currentType)
  }
  get isNumberType () {
    return ['number'].includes(this.currentType)
  }
  // 取出单选 / 多选option
  optionValueChange (obj) {
    (this.form as any).options = []
    for(let key in obj) {
       (this.form as any).options.push(obj[key].value)
    }
  }
  // 取出数字型 options
  getNumberOptions (obj) {
    (this.form as any).units = obj
  }
  // 验证是否检查单位和条件为空
  verifNull (obj) {
    let isNull = true
    obj.units && obj.units.map(item => {
      isNull = (item.condition === '' && item.value === '') ? true : false
    })
    return isNull
  }
  submit () {
    // 取出单选 || 多选的 options的值,组合成后台需要的格式
    if (['select', 'multi-select'].includes(this.currentType)) {
      this.optionValueChange(this.selectOptions)
    } else if (this.currentType === 'number') {
      this.getNumberOptions(this.numberOptions)
    }
    this.dialogFormVisible = false
    let obj = {
      ...this.form,
      id: this.isNewCreate ? 0 : (this.currentObj as any).id,
      type: this.currentType,
      units: this.verifNull(this.form) ? [] : (this.form as any).units
    }
    this.$emit('fieldSubmit', obj, this.isNewCreate)
  }
  cancel () {
    this.dialogFormVisible = false
  }
  typeChange (type) {
    switch (type) {
      case 'image':
        return '图片'
      case 'number':
        return '数值'
      case 'select':
        return '单选'
      case 'multi-select':
        return '多选'
      case 'text':
        return '文本'
      case 'date':
        return '日期'
      case 'dicom':
        return 'DICOM'
      case 'time':
        return '时刻'
    }
  }
  dialogShow (index, row, flag) {
    // isNewCreate: 判断是新增还是修改
    let name = this.typeChange(row.type)
    this.dialogFormVisible = true
    this.currentType = row.type
    this.dialogTitle = '设置题目' + '(' + name + '类型)'
    this.isNewCreate = flag.newadd
    this.transformData(index, row)
  }
  fieldUpdate (data) {
    this.$emit('groupFormFieldUpdate', data)
  }
  deleteField (index, row) {
    let currentField = (this.currentListData as any).fields
    this.$emit('deleteForm', currentField[index].id, {flag: 'field'})
  }
  // 清除数据
  clearData () {
    (this.$refs.topicTable as any).clearTableData()
  }
  // 表单反值
  transformData (index, row) {
    this.currentObj = row
    let obj = {
      ...row
    }
    if (this.isSelectOrMulti) {
      // 单选, 多选
      this.selectOptions = []
      obj.options.map((item, index) => {
         this.selectOptions.push({
           value: item
         })
      })
    } else if (this.isNumberType) {
        // 数字类型
      if (obj.units && obj.units !== null) {
        this.numberOptions = obj.units
      } else {
        this.numberOptions = [{
          value: '',
          condition: ''
        }]
      }
    }
    this.form = Object.assign({}, this.form, {
      name: obj.name,
      is_required: obj.is_required,
      is_enabled: obj.is_enabled
    })
  }
  selectTopic (value, name) {
    if (this.configFlag) {
      if (this.$refs.listForm as any) {
        (this.$refs.listForm as any).resetFields()
      }
      this.selectOptions = [{
        value: ''
      }]
      this.numberOptions = [{
        value: '',
        condition: ''
      }]
      this.dialogFormVisible = true
      this.currentType = value
      this.dialogTitle = '设置题目' + '(' + name + '类型)'
      this.form = {
        name: '',
        is_required: true,
        is_enabled: true,
        options: []
      }
      this.isNewCreate = true
    } else {
      this.warnMsg('请选择二级菜单后再操作题目!')
    }
  }
  // select型 & mulit-select型
  // 增加选项
  addSelectOption () {
    this.selectOptions.push({
      value: ''
    })
  }
  // 删除选项
  delSelectOption (item, index) {
    this.selectOptions.splice(index, 1)
  }
  // number型
  // 增加选项
  addUnitOption () {
    this.numberOptions.push({
      value: '',
      condition: ''
    })
  }
  delNumberOption (index) {
    // 第一个单位设置不可以删除
    if (index === 0) {
      this.warnMsg('不可删除, 至少保留一个单位设置!')
      return
    } 
    this.numberOptions.splice(index, 1)
  }
}
